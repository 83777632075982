<template>
	<div>
		<v-layout class="p-4 border-bottom-light-grey min-height-57px">
			<v-flex class="font-level-3-bold my-auto">
				<span class="detail-svg-icon mr-2">
					<!--begin::Svg Icon-->
					<inline-svg :src="$assetURL('media/custom-svg/services-color.svg')" />
					<!--end::Svg Icon-->
				</span>
				Services
			</v-flex>
			<v-flex class="text-right my-auto"> Total Services Cost: {{ totalCost }} </v-flex>
		</v-layout>
		<div class="overflow-y" style="max-height: calc(100vh - 306px)">
			<table width="100%" class="detail-table table-head-sticky">
				<thead>
					<tr>
						<th width="20" class="p-2 light-blue-bg"></th>
						<th class="p-2 light-blue-bg">Type</th>
						<th class="p-2 light-blue-bg">Status</th>
						<th class="p-2 light-blue-bg">Description</th>
						<th class="p-2 light-blue-bg">Cost</th>
						<th class="p-2 light-blue-bg">Serviced By</th>
						<th class="p-2 light-blue-bg">Linked Perishables Items</th>
						<th class="p-2 light-blue-bg">Expected Dates</th>
						<th class="p-2 light-blue-bg">Actual Dates</th>
					</tr>
				</thead>
				<tbody v-if="services.length">
					<tr v-for="(row, index) in services" :key="index">
						<td v-on:click="routeToDetail(row)" width="20" class="p-2 cursor-pointer">
							<v-chip v-if="row.type == 1" color="blue darken-4" text-color="white">M</v-chip>
							<v-chip v-else color="amber darken-4" text-color="white">S</v-chip>
						</td>
						<td v-on:click="routeToDetail(row)" class="p-2 cursor-pointer">
							<ShowValue :object="row" object-key="service_type" label="Type"></ShowValue>
						</td>
						<td v-on:click="routeToDetail(row)" class="p-2 cursor-pointer">
							<v-chip v-if="row.status == 1" color="blue darken-4" text-color="white">Started</v-chip>
							<v-chip v-else-if="row.status == 2" color="green" text-color="white">Completed</v-chip>
							<v-chip v-else color="amber darken-4" text-color="white">Scheduled</v-chip>
						</td>
						<td v-on:click="routeToDetail(row)" class="p-10 cursor-pointer d-grid" width="200">
							<ShowValue :object="row" object-key="description" truncate label="Description"></ShowValue>
						</td>
						<td v-on:click="routeToDetail(row)" class="p-2 cursor-pointer">
							<ShowValue :object="row" object-key="cost_formatted" label="Cost"></ShowValue>
						</td>
						<td class="p-2">
							<template v-if="row.service_by == 1">
								Supplier (<ShowValue
									:object="row"
									object-key="vendor_display_name"
									label="Supplier"
								></ShowValue
								>)
							</template>
							<template v-else>
								Staff (<ShowValue :object="row" object-key="member_display_name" label="Staff"></ShowValue>)
							</template>
						</td>
						<td class="p-2">
							<ShowValue
								:object="row"
								object-key="linked_items"
								label="Linked Perishables Items"
							></ShowValue>

							<!-- <template v-if="row.linked_items > 0"></template> -->
						</td>
						<td v-on:click="routeToDetail(row)" class="p-2 cursor-pointer" width="230">
							<div class="d-flex align-items-center">
								<div class="ml-2">
									<div>
										<v-tooltip top content-class="custom-top-tooltip">
											<template v-slot:activator="{ on, attrs }">
												<label v-bind="attrs" v-on="on" class="font-size-14 mb-1 mr-7">
													<v-badge color="cyan" content="S" class="badge-new"></v-badge>
												</label>
											</template>
											<span> Start Date </span>
										</v-tooltip>
										<v-chip label class="mb-2">
											{{ row.expected_start_date_formatted }}
										</v-chip>
									</div>
									<div>
										<v-tooltip top content-class="custom-top-tooltip">
											<template v-slot:activator="{ on, attrs }">
												<label v-bind="attrs" v-on="on" class="font-size-14 mb-1 mr-7">
													<v-badge color="red" content="E" class="badge-new"></v-badge>
												</label>
											</template>
											<span> Start Date </span>
										</v-tooltip>
										<v-chip label class="mb-2">
											{{ row.expected_end_date_formatted }}
										</v-chip>
									</div>
								</div>
							</div>
						</td>
						<td v-on:click="routeToDetail(row)" class="p-2 cursor-pointer" width="230">
							<div class="d-flex align-items-center">
								<div class="ml-2">
									<div>
										<v-tooltip top content-class="custom-top-tooltip">
											<template v-slot:activator="{ on, attrs }">
												<label v-bind="attrs" v-on="on" class="font-size-14 mb-1 mr-7">
													<v-badge color="cyan" content="S" class="badge-new"></v-badge>
												</label>
											</template>
											<span> Start Date </span>
										</v-tooltip>
										<v-chip label class="mb-2">
											{{ row.actual_start_date_formatted }}
										</v-chip>
									</div>
									<div>
										<v-tooltip top content-class="custom-top-tooltip">
											<template v-slot:activator="{ on, attrs }">
												<label v-bind="attrs" v-on="on" class="font-size-14 mb-1 mr-7">
													<v-badge color="red" content="E" class="badge-new"></v-badge>
												</label>
											</template>
											<span> Start Date </span>
										</v-tooltip>
										<v-chip label class="mb-2">
											{{ row.actual_end_date_formatted }}
										</v-chip>
									</div>
								</div>
							</div>
						</td>
					</tr>
				</tbody>
				<tfoot v-else>
					<tr>
						<td colspan="11">
							<p class="m-0 row-not-found text-center py-3">
								<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
								Uhh... There are no service at the moment.
							</p>
						</td>
					</tr>
				</tfoot>
			</table>
		</div>
		<Dialog :dialog="detailDialog" :dialog-width="dialogWidth" dense>
			<template v-slot:title>
				<v-layout>
					<v-flex md4 class="my-auto"> {{ service.service_type }} Service </v-flex>
					<v-flex md8 class="text-right">
						<v-btn
							v-if="service.status == 3"
							color="blue darken-4 text-white"
							class="mr-2"
							depressed
							v-on:click="startScheduledService"
							tile
							><v-icon small left>mdi-hammer-wrench</v-icon>Start</v-btn
						>
						<v-btn
							v-if="service.status == 1"
							v-on:click="serviceCompleteDialog = true"
							color="blue darken-4 text-white"
							class="mx-2"
							depressed
							tile
							><v-icon small left>mdi-format-list-checks</v-icon>Complete</v-btn
						>
						<v-btn
							v-if="service.status == 1"
							v-on:click="serviceExtendDialog = true"
							color="blue darken-4 text-white"
							class="mx-2"
							depressed
							tile
							><v-icon small left>mdi-share-all-outline</v-icon>Extend</v-btn
						>
						<v-btn
							v-on:click="detailDialog = false"
							color="blue darken-4 text-white"
							class="ml-2"
							depressed
							tile
							><v-icon small left>mdi-close</v-icon>Close</v-btn
						>
					</v-flex>
				</v-layout>
			</template>
			<template v-slot:body>
				<div>
					<v-tabs
						v-model="serviceTab"
						background-color="transparent"
						color="blue"
						class="custom-tab-transparent"
						active-class="blue darken-4 text-white"
						hide-slider
					>
						<v-tab href="#detail">
							<span class="tab-svg-icon mr-2">
								<!--begin::Svg Icon-->
								<inline-svg :src="$assetURL('media/custom-svg/services.svg')" />
								<!--end::Svg Icon-->
							</span>
							Details
						</v-tab>
						<v-tab href="#linked-inventory" v-on:click="getLinkedInventories()">
							<span class="tab-svg-icon mr-2">
								<!--begin::Svg Icon-->
								<inline-svg :src="$assetURL('media/custom-svg/inventory.svg')" />
								<!--end::Svg Icon-->
							</span>
							Linked Perishables
						</v-tab>
						<v-tab href="#file"> <v-icon small left>mdi-file-document-multiple</v-icon> Files</v-tab>
						<v-tab href="#comment"> <v-icon small left>mdi-comment-multiple</v-icon> Comments</v-tab>
					</v-tabs>
					<v-tabs-items v-model="serviceTab">
						<v-tab-item value="detail">
							<div class="mx-4 mb-4">
								<div class="overflow-y" style="max-height: 600px; min-height: 400px">
									<v-layout class="p-4 border-bottom-light-grey min-height-57px">
										<v-flex class="font-level-3-bold my-auto">
											<span class="detail-svg-icon mr-2">
												<!--begin::Svg Icon-->
												<inline-svg :src="$assetURL('media/custom-svg/services-color.svg')" />
												<!--end::Svg Icon-->
											</span>
											Service Details
										</v-flex>
									</v-layout>
									<table width="100%" class="detail-table scattered-table">
										<tr>
											<th class="p-2" width="250px">Service Type</th>
											<td class="font-level-1 p-2">
												<ShowValue :object="service" object-key="service_type" label="service type"></ShowValue>
											</td>
											<th class="p-2" width="250px">Service Start Date (expected)</th>
											<td class="font-level-1 p-2">
												<ShowValue
													:object="service"
													object-key="expected_start_date_formatted"
													label="service start date (expected)"
												></ShowValue>
											</td>
										</tr>
										<tr>
											<th class="p-2" width="250px">Cost</th>
											<td class="font-level-1 p-2">
												<ShowValue :object="service" object-key="cost_formatted" label="Cost"></ShowValue>
											</td>
											<th class="p-2" width="250px">Service End Date (expected)</th>
											<td class="font-level-1 p-2">
												<ShowValue
													:object="service"
													object-key="expected_end_date_formatted"
													label="Service End Date (expected)"
												></ShowValue>
											</td>
										</tr>
										<tr>
											<th class="p-2" width="250px">Service By</th>
											<td class="font-level-1 p-2">
												<ShowValue
													:object="service"
													object-key="service_by_formatted"
													label="Service By"
												></ShowValue>
												(<ShowValue
													:object="service"
													v-if="service.service_by == 1"
													object-key="vendor_display_name"
													label="vendor"
												></ShowValue>
												<ShowValue
													:object="service"
													v-else
													object-key="member_display_name"
													label="member"
												></ShowValue
												>)
											</td>
											<th class="p-2" width="250px">Service Start Date (actual)</th>
											<td class="font-level-1 p-2">
												<ShowValue
													:object="service"
													object-key="actual_start_date_formatted"
													label="service start date (actual)"
												></ShowValue>
											</td>
										</tr>
										<tr>
											<th class="p-2" width="250px">Created By</th>
											<td class="font-level-1 p-2">
												<ShowValue :object="service" object-key="created_by" label="Created By"></ShowValue>
											</td>
											<th class="p-2" width="250px">Service End Date (actual)</th>
											<td class="font-level-1 p-2">
												<ShowValue
													:object="service"
													object-key="actual_end_date_formatted"
													label="Service End Date (actual)"
												></ShowValue>
											</td>
										</tr>
										<tr>
											<th class="p-2" width="250px">Location By</th>
											<td class="font-level-1 p-2">
												<ShowValue
													:object="service"
													object-key="location_by_formatted"
													label="Loaction By"
												></ShowValue>
											</td>
										</tr>

										<tr>
											<th class="p-2" width="250px">Description</th>
											<td class="font-level-1 p-2" colspan="3">
												<ShowValue :object="service" object-key="description" label="Description"></ShowValue>
											</td>
										</tr>
									</table>
								</div>
							</div>
						</v-tab-item>
						<v-tab-item value="linked-inventory">
							<div class="mx-4 mb-4">
								<v-layout class="p-4 border-bottom-light-grey min-height-57px">
									<v-flex md6 class="font-level-3-bold my-auto">
										<table width="100%">
											<tr>
												<td>
													<span class="detail-svg-icon mr-2">
														<!--begin::Svg Icon-->
														<inline-svg :src="$assetURL('media/custom-svg/inventory-color.svg')" />
														<!--end::Svg Icon-->
													</span>
												</td>
												<td>
													<p class="m-0 mb-2">Linked Perishables</p>
													<span class="font-small text-muted">
														<v-icon small>mdi-progress-question</v-icon> Items displayed here were consumed during
														the service.</span
													>
												</td>
											</tr>
										</table>
									</v-flex>
									<v-flex md6 class="font-level-3-bold my-auto text-right">
										<v-btn
											v-if="service.status != 2"
											v-on:click="openDialog()"
											color="blue darken-4 text-white"
											depressed
											tile
											><v-icon small left>mdi-playlist-plus</v-icon>Link Perishables Items</v-btn
										>
										<!-- <v-btn
										    v-if="service.status != 2"
											class="white--text mt-3"
											:loading="pageLoading"
											:disabled="!formValid || pageLoading"
											depressed
											color="blue darken-4"
											tile
											v-on:click="openDialog()"
										>
											Perishables
										</v-btn> -->
									</v-flex>
								</v-layout>
								<div
									class="overflow-y border-light-grey"
									style="max-height: calc(100vh - 250px); min-height: 400px"
								>
									<table width="100%" class="detail-table scattered-table table-head-sticky">
										<thead>
											<tr>
												<th class="p-2 light-blue-bg font-level-1-bold">Perishables #</th>
												<th class="p-2 light-blue-bg font-level-1-bold">Barcode</th>
												<th class="p-2 light-blue-bg font-level-1-bold">Name</th>
												<th class="p-2 light-blue-bg font-level-1-bold">Quantity</th>
												<th v-if="false" class="p-2 light-blue-bg font-level-1-bold">Location</th>
												<th class="p-2 light-blue-bg font-level-1-bold">Date Added</th>
											</tr>
										</thead>
										<tbody v-if="linkedInventories.length">
											<tr v-for="(row, index) in linkedInventories" :key="index">
												<td class="border-top-light-grey border-right-light-grey font-level-1 p-2">
													<ShowValue
														:object="row"
														object-key="inventory_barcode"
														label="inventory #"
													></ShowValue>
												</td>
												<td class="border-top-light-grey border-right-light-grey font-level-1 p-2">
													<ShowValue
														:object="row"
														object-key="inventory_pico_barcode"
														label="inventory Barcode"
													></ShowValue>
												</td>
												<td class="border-top-light-grey border-right-light-grey font-level-1 p-2">
													<ShowValue :object="row" object-key="inventory_name" label="name"></ShowValue>
												</td>
												<td class="border-top-light-grey border-right-light-grey font-level-1 p-2">
													<ShowValue :object="row" object-key="quantity" label="quantity"></ShowValue>
												</td>
												<td v-if="false" class="border-top-light-grey border-right-light-grey font-level-1 p-2">
													<ShowValue :object="row" object-key="location_name" label="location"></ShowValue>
												</td>
												<td class="border-top-light-grey font-level-1 p-2">
													<ShowValue
														tooltip
														tooltip-value="added_at"
														:object="row"
														object-key="created_at"
														label="date added"
													></ShowValue>
												</td>
											</tr>
										</tbody>
										<tfoot v-else>
											<tr>
												<td colspan="5">
													<p class="m-0 row-not-found text-center">
														<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
														Uhh... There are no linked inventory at the moment.
													</p>
												</td>
											</tr>
										</tfoot>
									</table>
								</div>
							</div>
						</v-tab-item>
						<v-tab-item value="file">
							<Files
								class="mx-4"
								type-text="Service Files"
								type="service"
								:type-uuid="service.uuid"
							></Files>
						</v-tab-item>
						<v-tab-item value="comment">
							<Comments
								class="mx-4"
								type-text="Service"
								type="service"
								:type-uuid="service.uuid"
							></Comments>
						</v-tab-item>
					</v-tabs-items>
				</div>
			</template>
		</Dialog>
		<!-- <Dialog :dialog="inventoryDialog" :dialog-width="dialogWidth - 200" dense>
			<template v-slot:title> Link Perishables Items</template>
			<template v-slot:body>
				<LinkAssetInventoryItem v-model="inventories" class="mx-4 mb-4"></LinkAssetInventoryItem>
			</template>
			<template v-slot:action>
				<v-btn v-on:click="closeLinkInventory()" color="blue darken-4 text-white" depressed tile
					>Close</v-btn
				>
				<v-btn v-on:click="linkInventory()" color="blue darken-4 text-white" depressed tile
					>Link Perishables</v-btn
				>
			</template>
		</Dialog> -->
		<Dialog :dialog="dialog" :dialog-width="dialogWidth">
			<template v-slot:title> Select Perishables </template>
			<template v-slot:body>
				<v-row class="mx-1">
					<v-col v-if="alphabets.length" md="12" class="alphabets-filter">
						<div class="alphabets-scrollable">
							<v-btn
								:disabled="searching"
								depressed
								v-on:click="getLineItem(null, 'alphabet')"
								:class="{
									'cyan--text': null == alphabet,
									'cyan white--text': null != alphabet,
								}"
								class="custom-bold-button"
								small
								>All</v-btn
							>
							<v-btn
								:disabled="searching"
								depressed
								v-for="(row, index) in alphabets"
								v-on:click="getLineItem(row, 'alphabet')"
								:class="{
									'cyan--text': row == alphabet,
									'cyan white--text': row != alphabet,
								}"
								class="custom-bold-button"
								small
								:key="index"
								>{{ row }}</v-btn
							>
						</div>
					</v-col>
					<v-col md="12">
						<v-layout>
							<v-flex md6 class="fw-700 font-size-17 color-custom-blue">
								<v-btn
									small
									depressed
									:disabled="searching"
									:class="{
										'green white--text': !show_selected,
										'white green--text': show_selected,
									}"
									class="font-size-17 fw-700"
									v-on:click="showAll()"
								>
									{{ getSelectedCount() }}
								</v-btn>

								out of
								<v-btn
									small
									depressed
									:disabled="searching"
									class="font-size-17 fw-700"
									color="white cyan--text"
									v-on:click="showAll()"
								>
									{{ inventoryList.length }}
								</v-btn>
								Perishables Selected
							</v-flex>
							<v-flex md6>
								<v-text-field
									v-model="search"
									label="Search"
									placeholder="Start typing then press enter to search..."
									dense
									filled
									solo
									flat
									:disabled="searching"
									color="cyan"
									append-icon="mdi-magnify"
									clearable
									v-on:click:clear="
										search = null;
										getLineItem(search, 'search');
									"
									v-on:keyup.enter="getLineItem(search, 'search')"
									v-on:click:append="getLineItem(search, 'search')"
								></v-text-field>
							</v-flex>
						</v-layout>
						<br />
						<div class="custom-border-top">
							<br />

							<table class="line-item-multiple-table" width="100%">
								<thead>
									<tr>
										<th class="p-2 light-blue-bg" width="5%">
											<v-checkbox
												:disabled="searching || !inventoryList.length"
												color="cyan"
												v-model="allcheckbox"
												hide-details
												class="m-0 p-0"
												v-on:change="checkAll($event)"
											></v-checkbox>
										</th>
										<th width="15%" class="p-2 light-blue-bg">Image</th>
										<th width="40%" class="p-2 light-blue-bg">Name</th>
										<th width="30%" class="p-2 light-blue-bg text-center">Barcode</th>

										<th width="10%" class="p-2 light-blue-bg">Quantity</th>
									</tr>
								</thead>
								<tbody v-if="inventoryList.length">
									<template v-for="(inventory, index) in inventoryList">
										<tr v-if="checkSelected(inventory)" :key="index">
											<td valign="top" width="5%">
												<v-checkbox
													color="cyan"
													hide-details
													:disabled="searching"
													v-model="inventoryList[index].status"
													v-on:change="validateCheckbox($event)"
													class="ml-1 p-0 mt-2"
												></v-checkbox>
											</td>
											<td class="p-2 border-top-light-grey">
												<ImageTemplate circle style="max-width: 50px" :src="inventory.image"> </ImageTemplate>
											</td>

											<td class="px-2 pb-3 my-auto font-level-1 text-black border-top-light-grey">
												<p class="m-0 my-auto mt-3">{{ inventory.text }}</p>
											</td>
											<td class="px-2 py-2 my-auto font-level-1 text-black border-top-light-grey">
												<div
													v-if="inventory.pico_barcode"
													v-on:click.stop.prevent="openPopup(inventory, 'barcode')"
													class="text-center"
												>
													<label class="font-size-14 mb-1">
														<v-icon size="30">mdi-barcode-scan</v-icon>
													</label>
													<div class="font-size-14 fw-600 mb-1 w-100" >
														{{ inventory.pico_barcode }}
													</div>
												</div>
												<div v-else class="text-center">
													<em class="text-muted">No Barcode</em>
												</div>
											</td>
											<td class="px-2 pb-3 font-level-1 text-black border-top-light-grey">
												<QuantityInput
													hide-details
													:disabled="pageLoading"
													:loading="pageLoading"
													placeholder="Quantity"
													v-model="inventoryList[index].quantity"
												></QuantityInput>
											</td>
										</tr>
									</template>
								</tbody>
								<tfoot v-else>
									<tr>
										<td colspan="5">
											<p class="m-0 row-not-found text-center fw-500 font-size-16">
												<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
												Uhh... There are no Perishables at the moment.
											</p>
										</td>
									</tr>
								</tfoot>
							</table>
						</div>
					</v-col>
				</v-row>
			</template>
			<template v-slot:action>
				<v-btn
					v-on:click="
						inventoryList = [];
						dialog = false;
					"
					:disabled="searching"
					class="custom-grey-border custom-bold-button"
					>Cancel</v-btn
				>
				<v-btn
					color="cyan white--text"
					class="custom-bold-button"
					v-on:click="() => linkInventory()"
					:disabled="searching"
					>Save</v-btn
				>
			</template>
		</Dialog>
		<ServiceCompleteTemplate
			type="Asset"
			endpoint="asset"
			:type-uuid="assetUuid"
			:service-uuid="service.uuid"
			v-on:success="
				serviceCompleteDialog = false;
				detailDialog = false;
				getServices();
			"
			v-on:close="serviceCompleteDialog = false"
			:service-dialog="serviceCompleteDialog"
		></ServiceCompleteTemplate>
		<ServiceExtendTemplate
			type="Asset"
			endpoint="asset"
			:type-uuid="assetUuid"
			:service-uuid="service.uuid"
			v-on:success="
				serviceExtendDialog = false;
				detailDialog = false;
				getServices();
			"
			v-on:close="serviceExtendDialog = false"
			:service-dialog="serviceExtendDialog"
		></ServiceExtendTemplate>

		<v-dialog
			origin="center center"
			v-model="barcodeDialog"
			persistent
			scrollable
			content-class="common-dialog"
			:max-width="500"
		>
			<v-card>
				<v-card-title
					class="headline dialog-custom-header text-uppercase custom-border-bottom custom-header-blue-text white-background"
				>
					{{ barcodeDialogTitle }}
				</v-card-title>
				<v-card-text>
					<div class="text-center mt-2">
						<img v-if="popup_image" class="mb-4" :src="popup_image" :alt="popup_text" width="400px" />
						<h3>{{ popup_text }}</h3>
					</div>
				</v-card-text>
				<v-divider class="my-0"></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn depressed tile v-on:click="barcodeDialog = false">Close </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import { toSafeInteger,cloneDeep,map,filter} from "lodash";
import {
	GetAssetServices,
	AssetServiceLinkInventory,
	GetAssetServiceLinkInventory,
} from "@/core/lib/asset.lib";
import ShowValue from "@/view/components/ShowValue";
import Dialog from "@/view/components/Dialog";
import Comments from "@/view/components/Comments";
import Files from "@/view/components/Files";
import ServiceCompleteTemplate from "@/view/components/ServiceCompleteTemplate";
import ServiceExtendTemplate from "@/view/components/ServiceExtendTemplate";
// import LinkAssetInventoryItem from "@/view/components/LinkAssetInventoryItem";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { EventBus } from "@/core/event-bus/event.bus";
import ApiService from "@/core/services/api.service";
import ImageTemplate from "@/view/components/Image";
import QuantityInput from "@/view/components/QuantityInput";
// import { toSafeInteger, cloneDeep, findIndex, map, filter, find } from "lodash";


export default {
	data() {
		return {
			services: [],
			linkedInventories: [],
			inventoryList: [],
			inventories: [],
			pageLoading: false,
			detailDialog: false,
			inventoryDialog: false,
			serviceCompleteDialog: false,
			serviceExtendDialog: false,
			searchLoading: false,
			formLoading: false,
			menuSearch: false,
			showingString: null,
			search: null,
			serviceTab: "detail",
			currentPage: 1,
			totalCost: 0,
			totalPage: 1,
			service: {},
			timeout: null,
			timeoutLimit: 500,
			dialog: false,
			searching: false,
			alphabet: null,
			show_selected: true,
			allcheckbox: false,
			barcodeDialog: false,
			barcodeDialogTitle: null,
			popup_image: null,
			popup_text: null,


			alphabets: [
				"a",
				"b",
				"c",
				"d",
				"e",
				"f",
				"g",
				"h",
				"i",
				"j",
				"k",
				"l",
				"m",
				"n",
				"o",
				"p",
				"q",
				"r",
				"s",
				"t",
				"u",
				"v",
				"w",
				"x",
				"y",
				"z",
			],
		};
	},
	props: {
		assetUuid: {
			type: String,
			data: null,
		},
	},
	methods: {
		getSelectedCount() {
			return filter(this.inventoryList, { status: true }).length;
		},
		checkSelected() {
			return true;
		},
		closeLinkInventory() {
			this.inventoryList = [];
			this.inventories = [];
			this.inventoryDialog = false;
			this.searchLoading = false;
			this.formLoading = false;
			this.menuSearch = false;
			this.search = null;
		},
		startScheduledService() {
			this.pageLoading = true;
			ApiService.patch(`asset/${this.assetUuid}/service/${this.service.uuid}/start`)
				.then(() => {
					this.detailDialog = false;
					this.getServices();
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getLinkedInventories() {
			if (!this.service.uuid) {
				return false;
			}

			GetAssetServiceLinkInventory(this.assetUuid, this.service.uuid)
				.then((data) => {
					this.linkedInventories = data;
				})
				.catch((error) => {
					this.logError(error);
				});
		},
		
		linkInventory() {
			let selectedData = filter(this.inventoryList, (row) => row.status);

			this.slectedInventories = selectedData;
			this.$emit("selectedPerishables", selectedData);

			this.dialog = false;

			if (!this.service?.uuid) {
				this.$store.commit(
					SET_ERROR,
					this.errors.concat([{ model: true, message: "Error! Missing service UUID." }])
				);
				return false;
			}

			if (!selectedData.length) {
				this.$store.commit(
					SET_ERROR,
					this.errors.concat([{ model: true, message: "Error! Please select at least one inventory." }])
				);
				return false;
			}

			this.formLoading = true;
			AssetServiceLinkInventory(this.assetUuid, this.service.uuid, { inventory: selectedData })
				.then((data) => {
					this.linkedInventories = data;
					this.closeLinkInventory();

					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success! Perishables have been successfully linked to the service." },
					]);
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.formLoading = false;
				});
				},

		routeToDetail(row) {
			this.service = row;
			this.detailDialog = true;
		},
		getServices() {
			GetAssetServices(this.assetUuid)
				.then((data) => {
					this.services = data.tbody;
					this.showingString = data.showing_string;
					this.currentPage = data.current_page;
					this.totalCost = data.total_cost_formatted;
					this.totalPage = data.total_page;
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		openDialog() {
			this.allcheckbox = false;
			this.inventoryList = map(cloneDeep(this.inventoryListData), (row) => {
				let checkData = find(this.slectedInventories, (row1) => row1.status && row1.uuid == row.uuid);

				if (checkData) {
					row.quantity = checkData.quantity;
					row.status = true;
				} else {
					row.quantity = 1;
					row.status = false;
				}
				return row;
			});
			this.dialog = true;
		},
		validateCheckbox() {
			const len = filter(this.inventoryList, { status: true }).length;
			const default_len = this.inventoryList.length;
			if (default_len) {
				if (default_len == len) {
					this.allcheckbox = true;
				} else {
					this.allcheckbox = false;
				}
			} else {
				this.allcheckbox = false;
			}
		},
		checkAll(event) {
			if (event) {
				for (let i = this.inventoryList.length - 1; i >= 0; i--) {
					this.inventoryList[i].status = true;
				}
			} else {
				for (let z = this.inventoryList.length - 1; z >= 0; z--) {
					this.inventoryList[z].status = false;
				}
			}
		},
		savePerishables() {
			let selectedData = filter(this.inventoryList, (row) => row.status);
			this.slectedInventories = selectedData;
			this.$emit("selectedPerishables", selectedData);
			this.dialog = false;
			this.inventoryList = [];
		},
		openPopup(data) {
			this.popup_image = data.barcode_image;
			this.popup_text = data.pico_barcode;
			this.barcodeDialogTitle = `${data.text ? data.text : ""} (#${data.barcode})`;
			this.barcodeDialog = true;

			return false;
		},
		getLineItem(value, type) {  
			if (!this.originalInventoryList) {
				this.originalInventoryList = [...this.inventoryList];
			}
			if (type === "search") {
				this.filteredInventory = this.originalInventoryList.filter((item) =>
					item.text.toLowerCase().includes(value.toLowerCase()) ||
					(item.pico_barcode && item.pico_barcode.toLowerCase().includes(value.toLowerCase()))
				);
				this.inventoryList = this.filteredInventory; 
			} else if (type === "alphabet") {
				if (value == null || value === "") {
					this.filteredInventory = [...this.originalInventoryList];
					this.alphabet = "";
				} else {
					this.filteredInventory = this.originalInventoryList.filter((item) =>
						item.text.toLowerCase().startsWith(value.toLowerCase())
					);
					this.alphabet = value;
				}
				this.inventoryList = this.filteredInventory; 
			}
		}


	},
	components: {
		Dialog,
		ShowValue,
		Comments,
		Files,
		ServiceExtendTemplate,
		// LinkAssetInventoryItem,
		ServiceCompleteTemplate,
		ImageTemplate,
		QuantityInput,
	},
	mounted() {
		this.getServices();

		EventBus.$on("reload:asset-service", () => {
			this.getServices();
		});
		this.inventoryListData = this.localDB("inventories", []);

	},
	beforeDestroy() {
		EventBus.$off("reload:asset-service");
	},
	computed: {
		...mapGetters(["errors", "localDB","getUnLinkInv"]),

		dialogWidth() {
			return toSafeInteger((document.body.clientWidth / 100) * 65);
		},
	},
};
</script>
